<template>
  <div class="content">
    <Nav></Nav>
    <Navbj></Navbj>
    <div class="cont_inform">
      <div><img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/9.png" alt="">首页 >  <span>加入我们</span></div>
    </div>
    <div class="cont_data">
       <div class="data_list" v-for="(item,index) in list" :key="index">
          <p></p>
          <p>{{item.title}}</p>
          <p>{{item.subtitle}}</p>
          <p>{{item.info}}</p>
         <img :src="item.img" alt="">
       </div>

    </div>
    <div class="cont_contact">
       <p></p>
       <p>联系我们</p>
       <p>深度内容合作/市场品牌合作</p>
       <p>联系人：{{user.contacts }} </p>
       <p>电话：{{user.tel}}</p>
       <p>邮箱：{{user.email}}</p>
       <div>
         <img :src="user.wxewm" alt="">
       </div>
       <p>微信二维码</p>
        <p>{{user.company}}</p>
    </div>

    <Btm></Btm>
  </div>
</template>

<script>
import Nav from "@/components/pc-Nav/index"
import Btm from "@/components/pc-Btmfooter/index"
import Navbj from "@/components/pc-Nav/nav_bj";
import {all_list, jrwm} from "@/api/request/all";
import {mapState} from "vuex";
export default {
  name: "join",
  data(){
    return{
        list:'',

    }
  },
  components:{
    Nav,
    Navbj,
    Btm
  },
  computed: {
    ...mapState(['user','seo']),
  },
  metaInfo() {
    return {
      title: this.seo[5].title || this.user.indextitle,
      meta: [
        { name: 'description', content: this.seo[5].description ||this.user.drp},
        { name: 'keywords', content:this.seo[5].keywords ||this.user.seo}
      ]
    };
  },
  mounted() {
    this.all_list();
  },
  methods:{
    all_list(){
      jrwm().then((res)=>{
        if (res.status == 1){
          this.list=res.data;
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.content{
  .cont_inform{
    width: 100%;
    height: 51px;
    background: #EEEEEE;
    div{
      width: 1243px;
      margin:0 auto;
      height: 51px;
      line-height: 51px;
      img{
        width: 16px;
        height: 20px;
        vertical-align: sub;
        margin-right: 9px;
        position: relative;
        top: -2px;
      }
      font-weight: 500;
      font-size: 18px;
      color: #666666;
      span{
        color: #222222;
      }
    }
  }
  .cont_data{
    width: 100%;
    background: #FFFFFF;
    padding-top: 66px;
    .data_list{
      width: 1243px;
      margin: 0 auto;
      position: relative;
      height: 430px;
      p:nth-child(1){
        width: 46px;
        height: 3px;
        background: #FFA71F;
        border-radius: 2px;
        position: absolute;
        top: 0;
        left: 0;

      }
      p:nth-child(2){
        font-weight: 800;
        font-size: 24px;
        color: #222222;
        position: absolute;
        left: 0;
        top:17px ;

      }
      p:nth-child(3){
        height: 18px;
        font-weight: bold;
        font-size: 18px;
        color: #222222;
        line-height: 48px;
        position: absolute;
        left: 0;
        top: 104px;
      }
      p:nth-child(4){
        width: 410px;
        height: 129px;
        font-weight: 500;
        font-size: 16px;
        color: #666666;
        line-height: 28px;
        position: absolute;
        left: 0;
        top: 144px;

      }
      img:nth-child(5){
        width: 759px;
        height: 328px;
        position: absolute;
        right: 0;
        top: 43px;
      }
    }

  }
  .cont_contact{
    background: url('https://qiniu.fanyu889.com/cxmx/assets/pcimg/31.png') top center no-repeat;
    background-size: 100%;
    width: 1238px;
    height: 530px;
    margin: 30px auto 170px;
    position: relative;
    p:nth-child(1){
      width: 46px;
      height: 3px;
      background: #FFA71F;
      border-radius: 2px;
      position: absolute;
      top: 53px;
      left: 55px;
    }
    p:nth-child(2){
      font-weight: 800;
      font-size: 24px;
      color: #222222;
      position: absolute;
      left: 57px;
      top: 70px;
    }
    p:nth-child(3){
      font-weight: bold;
      font-size: 18px;
      color: #222222;
      line-height: 48px;
      position: absolute;
      left: 56px;
      top: 145px;
    }
    p:nth-child(4){
      font-weight: 500;
      font-size: 14px;
      color: #666666;
      position: absolute;
      top: 184px;
      left: 57px;
    }
    p:nth-child(5){
      font-weight: 500;
      font-size: 14px;
      color: #666666;
      position: absolute;
      top: 206px;
      left: 57px;
    }
    p:nth-child(6){
      font-weight: 500;
      font-size: 14px;
      color: #666666;
      position: absolute;
      top: 226px;
      left: 57px;
    }
    div{
      width: 180px;
      height: 180px;
      background: #999999;
      border-radius: 4px;
      position: absolute;
      top: 269px;
      left: 57px;
      img{
        width: 180px;
        height: 180px;
        border-radius: 4px;
      }
    }
    p:nth-child(8){
      font-weight: bold;
      font-size: 18px;
      color: #222222;
      width: 180px;
      text-align: center;
      position: absolute;
      top:460px;
      left: 57px;
    }
    p:nth-child(9){
      font-weight: bold;
      font-size: 22px;
      color: #222222;
      position: absolute;
      top: 120px;
      left: 57px;
    }

  }

}
</style>